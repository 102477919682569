form.newDestination {
    width: 75%;
    min-width: 350px;
    margin: 0 auto;
}

form.newDestination span {
    grid-column: 1 / span 2;
}

.form-btns {
    grid-column: 1 / span 2;
    min-width: 50px;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-btns button {
    display: inline-block;
    margin: 1rem;
}

form.newDestination label {
    font-size: 1.5rem;
}

p {
    margin: 0;
}

div.action-btn {
    padding: 0 0.5rem;
    margin: 0 0.5rem 0 0;
    font-size: 1rem;
}

.addTravelTips {
    display: inline-block;
    width: 40%;
    font-size: 0.75rem;
    padding: 0.25rem;
    margin: 0.5rem 0.25rem;
}

.travelTips {
    list-style-type: none;
    font-size: 0.8rem;
    padding: 0.25rem;
}

.addTravelTipPane {
    margin: 0;
}

.list-group-item .action-btn {
    font-size: 0.5rem;
    margin: 0 0.5rem 0 0;
}
