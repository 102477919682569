h1.h {
    text-align: left;
    font-weight: bold;
}

.experience-detail li {
    margin: 0;
}

.experience-detail a {
    text-decoration: none;
}

.experience-detail button {
    height: fit-content;
    margin: 0 0.25rem;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.25rem 1rem;
}

button.action-btn {
    padding: 0 0.5rem;
    margin: 0 0.5rem 0 0;
    font-size: 0.75rem;
}

button.done-btn {
    padding: 0 0.5rem;
    margin: 0 0.5rem 0 0;
    width: 4rem;
    height: 3rem;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
}

button.delete-experience-btn {
    font-size: 1rem;
}

.planItemTitle a {
    text-decoration: none;
    color: black;
}

.planItemTitle a::before {
    content: "🔗 ";
}

.green {
    color: green;
}

.grey {
    color: grey;
}

.h5 a {
    color: black;
}