h1.h {
    text-align: left;
    font-weight: bold;
}

.h4, .h5 {
    margin: 0;
}

h2.experiencesHeading {
    padding: 0;
}

.popularExperiences a {
    text-decoration: none;
    color: black;
}