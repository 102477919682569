.experience {
    width: fit-content;
    display: inline-block;
    margin: 0.25rem 0.25rem 0.25rem 0;
}
.experienceCard {
    width: 20rem;
    display: flex;
    min-height: 7.5rem;
    align-items: center;
    justify-content: center;
}

.experienceCard span {
    display: flex;
    min-height: 3rem;
    font-weight: bold;
    padding: 0.25vmin;
    font-size: 1.5rem;
    font-size: 1.5rem;
    min-width: 18.25rem;
    text-align: center;
    color: ghostwhite;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

.experienceCard button {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0 0.5rem;
    align-self: flex-start;
}

.experienceCard a {
    text-decoration: none;
}