:root {
    --hero1: no-repeat center/100% url(https://images.unsplash.com/photo-1545150665-c72a8f0cf311)
}

.heroBanner {
    background: var(--hero1);
    height: 40vmin;
}

.tagline {
    width: 50%;
    height: 100%;
    display: flex;
    color: white;
    padding: 0 20px; 
    font-size: 2.5vmin;
    min-width: 250px;
    align-items: center;
    justify-content: flex-start;
    -webkit-text-stroke-color: black;
    -webkit-text-stroke-width: 0.5px;
}