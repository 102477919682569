.authPage p {
    text-align: center;
    font-size: 2.5vmin;
  }
  
  .authPage button {
    font-size: 2.5vmin;
    text-transform: uppercase;
  }

  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .form-container input {
    font-size: 3vmin;
    width: 50vmin;
    grid-column: 1 / span 2;
  }
  
  .form-container button {
    grid-column: 1 / span 2;
    margin: 2vmin auto 0 auto;
  }
  