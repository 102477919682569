h1.h {
    text-align: left;
    font-weight: bold;
    margin: 2rem 0 0 0;
}

.profileDestinations a {
    text-decoration: none;
}

.badge-nav {
    width: fit-content;
    margin: 0 auto;
    font-size: 2.25vmin;
}

.badge-nav span {
    padding: 0.5rem 2rem;
    font-weight: 400;
    cursor: pointer;
}

.profile-detail {
    margin: 0;
}

.noFavoriteDestinations a {
    text-decoration: underline;
}

.profile-detail li {
    margin: 0;
    padding: 1rem;
}

.editProfile * {
    grid-column: 1 / span 2;
}

.editProfile button {
    width: fit-content;
}

.noPhoto span {
    margin: 0 0.25rem 0 0;
}