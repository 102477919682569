.destination {
    margin: 1rem 1rem 1rem 0;
    display: inline-block;
    width: fit-content;
}

.destinationCard {
    border-radius: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12rem;
    height: 8rem;
}

.destinationCard span {
    color: ghostwhite;
    background-color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    font-size: 1.5rem;
    padding: 0.25vmin;
    text-align: center;
}

.destination a {
    text-decoration: none;
}